<template>
  <div class="combine-wrapper overflow-y-auto fill-height px-5 pt-10 pb-5">
    <!--Header-->
    <v-text-field
      @click.stop
      prepend-inner-icon="$search"
      placeholder="Number"
      v-model="queryOptions.searchNumber"
      outlined
      clearable
      class="table-search d-inline-block mb-4 pa-1 input-clear-small border-black rounded-4"
      dense
    ></v-text-field>
    <v-row
      no-gutters
      class="mb-4 flex-grow-0"
      :class="{
      'mx-0': $vuetify.breakpoint.xsOnly,
      'flex-column': $vuetify.breakpoint.xsOnly,
      'align-start': $vuetify.breakpoint.xsOnly
      }"
    >
      <v-btn-toggle
        class="rounded-lg height-30 box-shadow interval black--text hidden"
        style="padding-left: 2px; padding-right: 2px"
        :class="{'mr-5': $vuetify.breakpoint.smAndUp}"
        background-color="#F2F4F5"
        v-model="interval"
        borderless
        dense>
        <v-btn
          class="rounded-lg my-auto fz-13 text-none"
          plain
          @click="getWeek"
          height="26">
          Week
        </v-btn>
        <v-btn
          class="rounded-lg my-auto fz-13 text-none"
          plain
          @click="getMonth"
          height="26">
          Month
        </v-btn>
        <v-btn
          class="rounded-lg my-auto fz-13 text-none"
          plain
          @click="getThreeMonth"
          height="26">
          3 months
        </v-btn>
        <v-btn
          class="rounded-lg my-auto fz-13 text-none"
          plain
          @click="getYear"
          height="26">
          {{ getYearNow }} year
        </v-btn>
        <v-btn
          class="rounded-lg my-auto fz-13 text-none"
          plain
          @click="getAllTime"
          height="26">
          All time
        </v-btn>
      </v-btn-toggle>
      <v-btn
        color="#010820"
        class="rounded-lg fz-13 fw-600 text-none mr-5"
        :class="{'mt-4': $vuetify.breakpoint.xsOnly}"
        height="30"
        @click="showDatepicker"
        outlined>
        {{
          queryOptions.dateStart && queryOptions.dateEnd ? 'Period: ' + queryOptions.dateStart + ' - ' + queryOptions.dateEnd : 'Specify period'
        }}
        <div @click.stop>
          <v-icon size="16" v-if="queryOptions.dateStart && queryOptions.dateEnd" class="ml-2" @click="getAllTime">
            mdi-close
          </v-icon>
        </div>
      </v-btn>
      <!--          https://vcalendar.io/ add calendar-->
    </v-row>
    <!--Orders-->
    <v-row no-gutters class="combine d-flex flex-nowrap">
      <div
        class="col-3 combine-col py-4 px-2"
        v-for="(status, key) in draggableList"
        :key="status.status + key"
      >
        <div
          class="pl-2 pb-2 d-flex justify-space-between"
          :style="{borderBottom: '2px solid ' + justColor(key), fontSize: '14px'}"
        >
          <div>
            <span>{{ statusValueToName(key) }}</span>
            <span v-if="status.statistics.count"> ({{ status.statistics.count }})</span>
          </div>
          <v-tooltip bottom v-if="status.statistics.count">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="one-line py-0 d-flex"
              >
                <span>Total:&nbsp;</span>
                <div class="d-flex flex-column align-end">
                  <span v-if="status.statistics.valueUsd">{{ Math.round(status.statistics.valueUsd) }}$</span>
                  <span v-if="status.statistics.valueRub">{{ Math.round(status.statistics.valueRub) }}₽</span>
                </div>
              </div>
            </template>
            <v-col class="d-flex flex-column flex-wrap pa-2">
              <div v-if="status.statistics.count">Count: {{ Math.round(status.statistics.count) }}</div>
              <div v-if="status.statistics.valueUsd">Total: {{ Math.round(status.statistics.valueUsd) }}$</div>
              <div v-if="status.statistics.valueRub">Total: {{ Math.round(status.statistics.valueRub) }}₽</div>
            </v-col>
          </v-tooltip>
        </div>
        <div v-for="(order, index) in status.cards" :key="order.id + order.status.value + index">
          <v-card
            v-if="status.isShowAllCards || index < 10"
            @click="$refs.editOrder.getOrder(order.id)"
            @contextmenu="openContextMenu($event, order.id)"
            class="mt-4 mb-4 pa-4 rounded-4 card-flex card-hover pointer hidden"
            outlined
          >
            <v-row no-gutters class="mb-3">
              <div class="mb-1">
                <span>{{ order.client.name }}</span>
              </div>
              <v-spacer/>
              <span class="text-gray">№{{ order.number }}</span>
            </v-row>
            <div class="mb-1">
              <span class="text-gray">Name: </span>
              <span>{{ order.name }}</span>
            </div>
            <div class="mb-1">
              <span class="text-gray">Start: </span>
              <span>{{ formatDate(order.dateStart) }}</span>
            </div>
            <div class="mb-1">
              <span class="text-gray">End: </span>
              <span>{{ formatDate(order.dateEnd) }}</span>
            </div>
            <div class="mb-1">
              <span class="text-gray">Assignee: </span>
              <span v-if="order.executors.length === 0" class="text-black">
                Not assigned
              </span>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="one-line"
                    >
                      <span>
                        <span class="text-black">{{ order.executors[0].businessName }}</span>
                        <span v-if="order.executors.length > 1" class="grey--text">
                           and {{ order.executors.length - 1 }}
                        </span>
                      </span>
                    </span>
                </template>
                <v-col class="d-flex flex-column flex-wrap">
                  <div
                    v-for="(executor, index) in order.executors"
                    :key="executor.businessName"
                  >
                    {{ executor.businessName }}
                    <v-divider v-if="order.executors.length - 1 !== index  && order.executors.length !== 1"
                               class="white opacity-25 my-2"></v-divider>
                  </div>
                </v-col>
              </v-tooltip>
            </div>
            <div class="mb-1">
              <span class="text-gray">Manager: </span>
              <span>{{ order.manager.businessName }}</span>
              <v-avatar
                :color="order.manager.avatarUrl ? 'transparent' : 'primary'"
                size="22"
                class="ml-2 my-auto"
              >
                <img
                  v-if="order.manager.avatarUrl"
                  :src="order.manager.avatarUrl"
                >
                <span v-else class="white--text">{{ order.manager.businessInitials || '??' }}</span>
              </v-avatar>
            </div>
            <v-row no-gutters justify="end">
              <div class="mt-2">
                <span class="text-gray">Cost: </span>
                <span
                  class="one-line my-auto"
                  :class="{'text-pink fw-600': !order.paymentDone }"
                >
                      {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                      {{ Math.round(+order.totalCost) }}
                      {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
              </span>
              </div>
            </v-row>
            <v-row no-gutters justify="end" v-if="order.status.value === 'IN_PROGRESS'">
              <div
                v-if="getQtyDay(order.dateEnd) > 0"
                :class="{'text-pink fw-600': getQtyDay(order.dateEnd) < 4}"
              >
                Осталось: {{ getQtyDay(order.dateEnd) + 1}} дней
              </div>
              <div
                v-else
                class="text-pink fw-600"
              >
                Истёк {{ Math.abs(getQtyDay(order.dateEnd)) - 1}} дней
              </div>
            </v-row>
          </v-card>
        </div>
        <v-btn
          v-if="!status.isShowAllCards && status.cards.length > 10"
          @click="status.isShowAllCards = true"
          height="30"
          outlined
          class="btn-primary rounded-lg text-none fz-13 fw-60 width-100"
        >
          Show all cards
        </v-btn>
      </div>
    </v-row>
    <!--Others-->
    <!--Contex menu-->
    <v-menu
      v-model="contextMenu.isOpen"
      min-width="150"
      height="600"
      :position-x="contextMenu.x"
      :position-y="contextMenu.y"
      absolute
      offset-y
    >
      <v-list class="py-2" color="scroll">
        <div class="pl-2 fz-14 text-gray">Action</div>
        <v-list-item
          style="min-height: 30px"
          class="px-0 py-1"
          @click="openOrder(contextMenu.selectedOrderId)"
        >
          <p class="ma-0 px-2 text-black">Open</p>
        </v-list-item>
        <v-list-item
          style="min-height: 30px"
          class="px-0 py-1"
          @click="openOrder(contextMenu.selectedOrderId, true)"
        >
          <p class="ma-0 px-2 text-black">Edit</p>
        </v-list-item>
        <RemoveDialog
          @commit="deleteOrder"
        >
          <template>
            <v-list-item
              style="min-height: 30px"
              class="px-0 py-1"
              @click="''"
            >
              <p class="ma-0 px-2">Delete</p>
            </v-list-item>
          </template>
          <template v-slot:message>
            this order
          </template>
        </RemoveDialog>
      </v-list>
    </v-menu>
    <!--Create btn-->
    <v-btn
      @click="$refs.editOrder.addNewOrder()"
      elevation="0"
      dark
      height="40"
      width="100"
      min-width="16"
      class="combine--btn-create rounded-lg mr-1 btn-create fz-14"
    >
      Create
    </v-btn>
    <!--DatePicker-->
    <v-row no-gutters>
      <vc-date-picker
        class="inline-block h-full"
        locale="en"
        :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
        :popover="{ visibility: 'click' }"
        :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
        @popoverWillShow="overlay = true"
        @popoverDidHide="overlay = false"
        mode="range"
        is-range
        :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
        v-model="date"
      >
        <template v-slot="">
          <button
            ref="datepickerShow"
            style="display: none"
          ></button>
        </template>
      </vc-date-picker>
    </v-row>
    <!--EditOrder-->
    <EditOrder
      @getOrders="getOrders"
      ref="editOrder"
    />
    <!--Overlay-->
    <v-overlay
      :value="overlay"
      :z-index="0"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
    />
    <!--Loading-->
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <!--Snackbar-->
    <Snackbar :messages="snackbarMessage"/>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { statusColor, statusJustColor } from '@/helpers/staticColors'
/* import draggable from 'vuedraggable' */
import { Locale } from 'v-calendar'
import EditOrder from '@/components/App/Orders/EditOrder'
import RemoveDialog from '@/components/App/Functional/RemoveDialog'
import Snackbar from '@/components/App/Functional/Snackbar'
import _ from 'lodash'

const locale = new Locale()

export default {
  name: 'kanban',
  components: {
    EditOrder,
    RemoveDialog,
    Snackbar
  },
  data() {
    return {
      snackbarMessage: [],
      contextMenu: {
        selectedOrderId: 0,
        isOpen: false,
        x: 0,
        y: 0
      },
      drag: true,
      overlay: false,
      draggableList: {
        BACKLOG: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        TODO: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        IN_PROGRESS: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        NEED_REVIEW: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        REVIEW: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        COMPLETED: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        PRE_SALE: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        CANCELED: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        }
      },
      interval: 4,
      loading: false,
      queryOptions: {
        dateEnd: null,
        dateStart: null,
        orderBy: 'NUMBER_DESC',
        paymentStatus: 'ALL',
        page: 1,
        pageSize: 9999,
        searchClient: '',
        searchManager: '',
        searchName: '',
        searchNumber: '',
        searchExecutor: '',
        orderStatuses: []
      },
      statusType: [
        {
          name: 'Backlog',
          status: 'BACKLOG'
        },
        {
          name: 'To do',
          status: 'TODO'
        },
        {
          name: 'In progress',
          status: 'IN_PROGRESS'
        },
        {
          name: 'Need Review',
          status: 'NEED_REVIEW'
        },
        {
          name: 'Review customer',
          status: 'REVIEW'
        },
        {
          name: 'Completed',
          status: 'COMPLETED'
        },
        {
          name: 'Pre sale',
          status: 'PRE_SALE'
        },
        {
          name: 'Canceled',
          status: 'CANCELED'
        }
      ]
    }
  },
  computed: {
    getYearNow () {
      const date = new Date()
      return date.getFullYear()
    },
    date: {
      get() {
        const object = {
          end: this.queryOptions.dateEnd ? locale.parse(this.queryOptions.dateEnd, 'DD.MM.YYYY') : new Date(),
          start: this.queryOptions.dateStart ? locale.parse(this.queryOptions.dateStart, 'DD.MM.YYYY') : new Date()
        }
        return object
      },
      set(val) {
        this.interval = -1
        this.queryOptions.dateStart = val.start ? locale.format(val.start, 'DD.MM.YYYY') : ''
        this.queryOptions.dateEnd = val.end ? locale.format(val.end, 'DD.MM.YYYY') : ''
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    openContextMenu($event, id) {
      $event.preventDefault()
      this.contextMenu.selectedOrderId = id
      this.contextMenu.x = $event.clientX
      this.contextMenu.y = $event.clientY
      this.$nextTick(() => {
        this.contextMenu.isOpen = true
      })
    },
    getWeek() {
      const end = new Date()
      const start = new Date()
      start.setDate(start.getDate() - 7)
      end.setDate(end.getDate() + 1)
      this.queryOptions.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.queryOptions.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 0
      }, 0)
    },
    getMonth() {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 1)
      end.setDate(end.getDate() + 1)
      this.queryOptions.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.queryOptions.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 1
      }, 0)
    },
    getThreeMonth() {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 3)
      end.setDate(end.getDate() + 1)
      this.queryOptions.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.queryOptions.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 2
      }, 0)
    },
    getYear() {
      this.queryOptions.dateStart = this.getYearNow + '-01-01'
      this.queryOptions.dateEnd = this.getYearNow + '-12-31'
      const vm = this
      setTimeout(function () {
        vm.interval = 3
      }, 0)
    },
    getAllTime() {
      this.queryOptions.dateStart = null
      this.queryOptions.dateEnd = null
      const vm = this
      setTimeout(function () {
        vm.interval = 4
      }, 0)
    },
    getQtyDay(date) {
      return Math.floor((Date.parse(date) - new Date()) / 1000 / 60 / 60 / 24)
    },
    showDatepicker() {
      const vm = this
      setTimeout(function () {
        vm.$refs.datepickerShow.click()
      }, 0)
    },
    statusValueToName(value) {
      for (const el of this.statusType) {
        if (value === el.status) {
          return el.name
        }
      }
    },
    color(value) {
      return statusColor(value)
    },
    justColor(value) {
      return statusJustColor(value)
    },
    sortOrdersByStatus(orders) {
      /* For refresh */
      this.draggableList = {
        BACKLOG: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        TODO: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        IN_PROGRESS: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        NEED_REVIEW: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        REVIEW: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        COMPLETED: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        PRE_SALE: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        },
        CANCELED: {
          isShowAllCards: false,
          cards: [],
          statistics: {}
        }
      }
      orders.forEach(el => {
        for (const key of Object.keys(this.draggableList)) {
          if (el.status.value === key) {
            this.draggableList[key].cards.push(el)
          }
        }
      })
    },
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    /* Crud */
    async getOrders() {
      this.loading = true
      _.throttle(async () => {
        await this.$apollo.query({
          query: gql`query getOrders ($input: DesignOrderFilterInput! ) {
          design {
            orders {
              paginatedOrders(input: $input) {
                data {
                  id
                  number
                  name
                  currency
                  currencySymbol
                  createdAt
                  dateEndPrediction
                  dateEnd
                  dateStart
                  expirationDate
                  updatedAt
                  type
                  paidCurrently
                  items {
                    dateEnd
                    expirationDate
                  }
                  niche {
                    id
                    name
                  }
                  contractTitle
                  contractUrl
#                  uploadContractUrl
                  invoices {
                    name
                    amount
                    id
                    downloadUrl
                  }
                  completionActs {
                    downloadUrl
                    name
                    id
                    invoice {
                      id
                    }
                  }
                  status {
                    label
                    value
                    id
                  }
                  manager {
                    businessName
                    avatarUrl
                    businessInitials
                  }
                  totalCost
                  client {
                    name
                  }
                  executors {
                      businessName
                  }
                  salaryPayments {
                    byDesigner {
                      paid
                      total
                      operations {
                        date
                        amount
                        amountOrderCurrency
                        id
                      }
                      designer {
                        avatarUrl
                        businessInitials
                        businessName
                        team
                        id
                      }
                    }
                    remainder
                    remainderOffice
                    remainderRemote
                    total
                    expectedTotal
                  }
                  operations {
                    moneyIn
                    amountOrderCurrency
                    date
                    id
                  }
                  paymentRemainder
                  paymentDone
                }
                totalCounts {
                  paid
                  total
                  unpaid
                }
                statusCounts {
                    status {
                        id
                        value
                        label
                    }
                    count
                }
                totalPages
                page
                totalItems
                hasNext
                hasPrev
              }
            }
          }
        }`,
          variables: {
            input: {
              dateEnd: this.queryOptions.dateEnd,
              dateStart: this.queryOptions.dateStart,
              orderBy: this.queryOptions.orderBy,
              page: this.queryOptions.page,
              pageSize: this.queryOptions.pageSize,
              paymentStatus: this.queryOptions.paymentStatus,
              searchClient: this.queryOptions.searchClient,
              searchId: this.queryOptions.searchNumber,
              searchName: this.queryOptions.searchName,
              searchManager: this.queryOptions.searchManager,
              searchExecutor: this.queryOptions.searchExecutor,
              orderStatuses: this.queryOptions.orderStatuses.map(item => item.value)
            }
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          const orders = data.data.design.orders.paginatedOrders.data
          this.sortOrdersByStatus(orders)
          this.getOrdersStatistics()
          this.loading = false
        }).catch((err) => {
          this.snackbarMessage = ['Error', err]
          this.loading = false
          console.log(err)
        })
      }, 1000, { leading: false, trailing: true })()
    },
    changeStatus(event, status) {
      if (event.added) {
        console.log(status)
        const item = event.added.element
        this.setStatusToOrder(item.id, status).then(() => {
          item.status = { value: status }
        })
      }
    },
    async setStatusToOrder(id, status) {
      await this.$apollo.query({
        query: gql`mutation setStatusToOrder($id: ID!, $status: DesignOrderStatusEnum!) {
            orders {
              order(id: $id) {
                setKanbanStatus(status: $status) {
                  kanbanStatus {
                    id
                    value
                  }
                }
              }
            }
          }`,
        variables: {
          id: id,
          status: status
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
      }).catch((err) => {
        this.snackbarMessage = ['Error', err]
        console.log(err)
      })
    },
    async deleteOrder () {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation DeleteOrder($id: ID! ) {
          design {
            orders {
              order(id: $id) {
                delete
              }
            }
          }
        }`,
        variables: {
          id: this.contextMenu.selectedOrderId
        }
      }).then(() => {
        this.getOrders()
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    async getOrdersStatistics() {
      await this.$apollo.query({
        query: gql`query getStatisticsForOrderDashboard($periodStart: datetime!, $periodEnd: datetime!) {
          design {
            orders {
              orderStatistics(input: {periodStart: $periodStart, periodEnd: $periodEnd}) {
                statuses {
                  count
                  status
                  valueUsd
                  valueRub
                }
              }
            }
          }
        }`,
        variables: {
          periodStart: this.queryOptions.dateStart ? this.queryOptions.dateStart : '2000-01-01',
          periodEnd: this.queryOptions.dateEnd ? this.queryOptions.dateEnd : '2099-01-01'
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const statistics = data.data.design.orders.orderStatistics.statuses
        statistics.forEach(el => {
          for (const key of Object.keys(this.draggableList)) {
            if (el.status === key) {
              this.draggableList[key].statistics = el
            }
          }
        })
      }).catch((err) => {
        this.snackbarMessage = ['Error', err]
        console.log(err)
      })
    }
  },
  mounted() {
    this.getMonth()
    this.getOrders()
  },
  watch: {
    queryOptions: {
      handler() {
        this.getOrders()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.combine-wrapper {
  .v-input__slot {
    margin-bottom: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.combine-wrapper {
  position: relative;
}

.combine--btn-create {
  position: fixed;
  z-index: 5;
  right: 25px;
  bottom: 25px;
}
.combine {
  &-col {
    max-width: 280px;
    font-size: 14px;
    background: #F4F5F7;
    border-radius: 16px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.v-menu__content .v-list-item--link {
  margin: 0;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
